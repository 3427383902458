import Swiper from 'swiper'

class Component {
  initState() {
    this.section = document.querySelector('.team')
    this.slider = document.querySelector('.team__slider')
    this.isColumn = Boolean(this.section?.classList.contains('team--col'))
    this.isMobile = window.matchMedia('(max-width: 767px)').matches

    this.swiper = null
  }

  init() {
    this.initState()

    if (this.slider) {
      new Swiper(this.slider, {
        speed: 1000,
        slidesPerView: 'auto',
        centeredSlides: false,
        centeredSlidesBounds: true,
        initialSlide: 0,
        roundLengths: true,
        momentum: 'false',
        slidesOffsetBefore: -1
      })
      return
    }

    if (this.slider && !this.isMobile) {
      this.swiper = new Swiper(this.slider, {
        speed: 1000,
        slidesPerView: 'auto',
        roundLengths: true,
        momentum: 'false'
      })
    }
  }

  destroy() {
    if (this.swiper) {
      this.swiper.destroy()
    }
  }
}

export default new Component()
