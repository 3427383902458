class Component {
  constructor() {}

  initState() {
    this.items = document.getElementsByClassName('approach-steps__head')
  }

  collapse(element) {
    const elementTransition = element.style.transition

    element.style.transition = ''

    requestAnimationFrame(function () {
      element.style.height = element.scrollHeight + 'px'
      element.style.transition = elementTransition

      requestAnimationFrame(function () {
        element.style.height = 0 + 'px'
      })
    })
  }

  expand(element) {
    element.style.height = element.scrollHeight + 'px'
  }

  handleClick(e) {
    const head = e.currentTarget
    const dropdown = head.nextElementSibling
    const isCollapsed = dropdown.getAttribute('data-collapsed') === 'true'

    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i] !== head) {
        let iDropdown = this.items[i].nextElementSibling

        if (iDropdown.getAttribute('data-collapsed') === 'false') {
          this.collapse(iDropdown)
          this.items[i].setAttribute('data-collapsed', 'true')
          iDropdown.setAttribute('data-collapsed', 'true')
        }
      }
    }

    if (isCollapsed) {
      this.expand(dropdown)

      head.setAttribute('data-collapsed', 'false')
      dropdown.setAttribute('data-collapsed', 'false')
    } else {
      this.collapse(dropdown)

      head.setAttribute('data-collapsed', 'true')
      dropdown.setAttribute('data-collapsed', 'true')
    }
  }

  init() {
    this.initState()

    for (let item of this.items) {
      item.addEventListener('click', this.handleClick.bind(this))
    }
  }

  destroy() {
    for (let item of this.items) {
      item.removeEventListener('click', this.handleClick)
    }
  }
}

export default new Component()
