class Component {
  constructor() {
    this.onScroll = this.onScroll.bind(this)
  }

  initState() {
    this.pageSticker = document.querySelector('.page-sticker')
    this.sticker = this.pageSticker?.querySelector('.sticker')
    this.stickerRect = this.pageSticker?.getBoundingClientRect()
    this.smallSections = document.querySelectorAll('.sm-sticker')
    this.isSmall = !!window.stickerWasSmall

    this.direction = null
    this.lastScrollY = 0
    this.wasScrolledUp = false
  }

  render() {
    if (this.isSmall) {
      this.sticker.classList.add('sticker--sm')
    } else {
      this.sticker.classList.remove('sticker--sm')
    }
  }

  onScroll() {
    if (this.lastScrollY > window.scrollY) {
      this.direction = 'up'
    }
    if (this.lastScrollY < window.scrollY) {
      this.direction = 'down'
    }

    this.lastScrollY = window.scrollY > 0 ? window.scrollY : 0

    if (this.direction === 'up' && window.scrollY > 200) {
      this.wasScrolledUp = true
    }

    if (this.wasScrolledUp) {
      this.isSmall = true
      window.stickerWasSmall = true
      this.render()
      return
    }

    if(!window.stickerWasSmall) {
      this.isSmall = Array.from(this.smallSections).map(section => {
        const sectionRect = section.getBoundingClientRect()
        return { top: sectionRect.top, bottom: sectionRect.bottom }
      })
          .some(({ top, bottom }) => {
            return top <= this.stickerRect.top && bottom >= this.stickerRect.bottom - this.stickerRect.height
          })
    }

    this.render()
  }

  init() {
    this.initState()

    if (this.pageSticker) {
      window.addEventListener('scroll', this.onScroll)
      this.onScroll()
    }

    const scrollToEl = window.location.hash ? document.querySelector(window.location.hash) : false

    if (scrollToEl) {
      const calibrateOffset = window.screen.width < 768 ? 0 : 100

      setTimeout(()=> {
        window.scrollTo({
          top: scrollToEl.offsetTop + calibrateOffset,
          behavior: 'smooth'
        })
      }, 500)
    }
  }

  destroy() {
    window.removeEventListener('scroll', this.onScroll)
  }
}

export default new Component()
