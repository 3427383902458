class Component {
  constructor() {
    this.getEntries = this.getEntries.bind(this)
  }

  initState() {
    this.list = document.querySelector('.overview__list')
    this.container = document.querySelector('.overview__actions')
    this.btn = document.querySelector('.overview__load-btn')
    this.pending = document.querySelector('.overview__load-pending')
    this.currentPage = 1
    this.total = this.container?.dataset.total
    this.limit = this.container?.dataset.limit
    this.allowLoad = this.currentPage < this.total
    this.observer = null
    this.isMobile = window.matchMedia('(max-width: 767px)').matches
  }

  getEntries() {
    if (!this.allowLoad) {
      return
    }

    this.btn.classList.add('hidden')
    this.pending.classList.remove('hidden')

    fetch('/ajax/loadMore/projects?' + new URLSearchParams({
      limit: this.limit,
      currentPage: this.currentPage
    }), {
      method: 'GET',
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
        .then(response => response.text())
        .then(response => {
          this.btn.classList.remove('hidden')
          this.pending.classList.add('hidden')
          this.list.insertAdjacentHTML('beforeend', response)

          if (this.currentPage < this.total) {
            this.currentPage++
          }

          if (this.currentPage >= this.total) {
            this.container.classList.add('hidden')
            this.allowLoad = false
          }
        }).catch(function () {
      this.btn.classList.remove('hidden')
      this.pending.classList.add('hidden')
      console.error('pagination error')
    })
  }

  init() {
    this.initState()

    if (!this.list || !this.btn) {
      return
    }

    this.btn.addEventListener('click', this.getEntries)

    if (this.isMobile) {
      return
    }

    this.observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.intersectionRatio > 0 && this.allowLoad) {
              this.getEntries()
            }
          })
        },
        {
          rootMargin: '400px 0px'
        }
    )
    this.observer.observe(this.container)
  }

  destroy() {
    if (this.btn) {
      this.btn.removeEventListener('click', this.getEntries)
    }

    if (this.observer) {
      this.observer.disconnect()
    }
  }
}

export default new Component()
