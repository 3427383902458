class Component {
  initState() {
    this.items = document.querySelectorAll('.animate')
    this.observer = null
  }

  init() {
    this.initState()

    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.remove('animate')
          entry.target.classList.add('animated')
        }
      })
    })

    Array.from(this.items).forEach(item => this.observer.observe(item))
  }

  destroy() {
    if (this.observer) {
      this.observer.disconnect()
    }
  }
}

export default new Component()
