import Swup from 'swup'
import SwupJsPlugin from '@swup/js-plugin'
import SwupPreloadPlugin from '@swup/preload-plugin'
import gsap from 'gsap'
import { shouldInit, shouldUpdate } from './index'

class Component {
  constructor() {
    this.duration = 1.5
    this.ease = 'power4.inOut'

    this.out = this.out.bind(this)
    this.in = this.in.bind(this)
  }

  initState() {
    this.overlay = document.querySelector('.transition-overlay')
    this.pageHeader = document.querySelector('.page-header')
    this.pageSticker = document.querySelector('.page-sticker')

    this.swup = null
  }

  out(next) {
    if (shouldInit.length) {
      shouldInit.forEach(component => component.pageout && component.pageout())
    }

    gsap.fromTo('.page-content', {
      top: 0,
      opacity: 1
    }, {
      top: '-10rem',
      opacity: 0,
      duration: this.duration,
      ease: this.ease,
      delay: 0.3
    })

    gsap.fromTo(this.overlay, {
      scaleY: 0
    }, {
      scaleY: 1,
      transformOrigin: 'bottom',
      duration: this.duration,
      ease: this.ease,
      delay: 0.3,
      onComplete: next
    })
  }

  in(next) {
    window.scrollTo(0, 0)

    if (shouldInit.length) {
      shouldInit.forEach(component => component.pagein && component.pagein())
    }

    gsap.fromTo('.page-content', {
      top: '10rem',
      opacity: 0
    }, {
      top: 0,
      opacity: 1,
      duration: this.duration,
      ease: this.ease
    })

    gsap.fromTo(this.overlay, {
      scaleY: 1
    }, {
      scaleY: 0,
      transformOrigin: 'top',
      duration: this.duration,
      ease: this.ease,
      onComplete() {
        if (shouldInit.length) {
          shouldInit.forEach(component => component.animationend && component.animationend())
        }
        next()
      }
    })
  }

  init() {
    this.initState()

    this.swup = new Swup({
      plugins: [
        new SwupPreloadPlugin(),
        new SwupJsPlugin([
          {
            from: '(.*)',
            to: '(.*)',
            out: this.out,
            in: this.in
          }
        ])
      ],
      animateHistoryBrowsing: true
    })

    this.swup.on('contentReplaced', () => {
      if (shouldUpdate.length) {
        shouldUpdate.forEach(component => component.init())
      }
    })

    this.swup.on('willReplaceContent', () => {
      if (shouldUpdate.length) {
        shouldUpdate.forEach(component => component.destroy && component.destroy())
      }
    })
  }

  destroy() { }
}

export default new Component()
