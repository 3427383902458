import Swiper from 'swiper'

class Component {
  initState() {
    this.slider = document.querySelector('.gallery-carousel__slider')
    this.swiper = null
  }

  init() {
    this.initState()

    if (this.slider) {
      this.swiper = new Swiper(this.slider, {
        speed: 500,
        slidesPerView: 'auto',
         momentum: 'false'
      })

      this.swiper.on('touchStart', () => {
        const style = document.createElement('style')
        style.id='remove_pinterest'
        style.innerHTML='span[class*="_save"] {display: none};'
        document.body.prepend(style)
      })

      this.swiper.on('transitionEnd', () => {
        const style = document.querySelector('#remove_pinterest')
        if(style)
          style.remove()
        // if(window.parsePinBtns) {
        //   window.parsePinBtns()
        // }
      })
    }
  }

  destroy() {
    if (this.swiper) {
      this.swiper.destroy()
    }
  }
}

export default new Component()
