import './scss/main.scss'
import setViewHeight from '@/utils/setViewHeight'
import lazySizes from 'lazysizes'
import { shouldInit } from './components'

setViewHeight()

lazySizes.cfg.expand = 1
lazySizes.cfg.expFactor = 1
lazySizes.cfg.throttleDelay = 200

if (shouldInit.length) {
  shouldInit.forEach(component => component.init())
}
