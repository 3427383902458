class Component {
  addVideoUrl() {
    const iframe = document.getElementById('heroVideo')
    if (!iframe)
      return

    const url = iframe.dataset.url
    const id = url.split('.com/')[1]
    const embedLink = `https://player.vimeo.com/video/${id}?controls=0&autoplay=1&loop=1&byline=0&title=0&muted=1`

    iframe.src = embedLink
  }

  init() {
    this.addVideoUrl()
  }

  destroy() {
  }
}

export default new Component()
