import Swiper, { Pagination, Grid } from 'swiper'
Swiper.use([Pagination, Grid])
class Component {
  initState() {
    this.slider = document.querySelector('.swiper__icons')
    this.swiper = null
  }

  init() {
    this.initState()

    if (this.slider) {
      new Swiper(this.slider, {
        speed: 1000,
        spaceBetween: 12,
        slidesPerView: 2,
        grid: {
          rows: 2,
          fill: 'column'
        },
        centeredSlides: false,
        centeredSlidesBounds: true,
        initialSlide: 0,
        roundLengths: true,
        momentum: 'false',
        slidesOffsetBefore: -1,
        pagination: {
          el: '.swiper-pagination',
          type: 'progressbar'
        },
        breakpoints: {
           768: {
             slidesPerView: 4,
             spaceBetween: 24,
             grid: {
               rows: 1
             }
           }
        }
      })
      return
    }
  }

  destroy() {
    if (this.swiper) {
      this.swiper.destroy()
    }
  }
}

export default new Component()
