class Component {
  addPins() {
    const imgs = document.querySelectorAll('img')
    imgs.forEach((img) => {
      img.setAttribute('data-pin-no-hover', 'true')
    })
  }

  init() {
    // this.addPins()
  }

  destroy() {
  }
}

export default new Component()
