class Component {
  constructor() {}

  initState() {

  }

  chunksText(link) {
    const symbolWidth = parseFloat(getComputedStyle(link).fontSize) * 19 / 31.48
    const text = link.innerText
    const texts = text.split(' ')
    const maxSymbols = link.clientWidth/symbolWidth

    let result = []
    let cur = []
    let len = 0

    for(let key in texts) {
      len += texts[key].length + 1

      let nextLen = 0
      if(texts[Number(key)+1]) {
        nextLen += texts[Number(key)+1].length
      }

      if(len + nextLen <= maxSymbols) {
        cur.push(texts[key])

        if(texts.length - 1 === Number(key)) {
          result = [...result, [...cur]]
          cur = []
          len = 0
        }
      } else {
        result = [...result, [...cur, texts[key]]]
        cur = []
        len = 0
      }
    }

    return result
  }

  renderLink(link) {
    const chunks = this.chunksText(link)

    link.innerHTML = null

    for(let key in chunks) {
      const span = document.createElement('span')
      span.innerText = chunks[key].join(' ')

      link.appendChild(span)
    }
  }

  init() {
    const items = document.getElementsByClassName('underline--multiple')

    for (let item of items) {
      this.renderLink(item)
    }
  }

  destroy() {}
}

export default new Component()
