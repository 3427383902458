class Component {
  constructor() {
    this.setHeight = this.setHeight.bind(this)
    this.stub = document.createElement('div')
    document.body.prepend(this.stub)
  }

  initState() {
    this.pageFooter = document.querySelector('.page-footer')
    this.pageCompensation = document.querySelector('.page-compensation')
    this.banner = document.querySelector('.banner')
  }

  setHeight() {
    const footerHeight = this.pageFooter.clientHeight
    const compensationHeight = this.pageCompensation.clientHeight
    this.stub.style.height = `${footerHeight - compensationHeight}px`
  }

  init() {
    this.initState()

    if (this.banner) {
      document.body.classList.add('with-banner')
    } else {
      document.body.classList.remove('with-banner')
    }

    if (this.pageFooter && this.pageCompensation) {
      window.addEventListener('resize', this.setHeight)
      this.setHeight()
    }
  }

  destroy() {
    window.removeEventListener('resize', this.onResize)
  }
}

export default new Component()
