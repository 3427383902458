import gsap from 'gsap'
import Swiper from 'swiper'

class Component {
  constructor() {
    this.duration = 0.8
    this.ease = 'power1.inOut'
    this.onScroll = this.onScroll.bind(this)
  }

  initState() {
    this.isMobile = window.matchMedia('(max-width: 767px)').matches
    this.items = []
    this.swipers = []
    this.activeIndex = 0
    this.lastActiveIndex = 0
    this.lastScrollY = 0
    this.direction = 'down'
  }

  render() {
    if (this.lastActiveIndex === this.activeIndex) {
      return
    }

    this.items.forEach((item, i) => {
      const text = item.querySelector('.approach-steps__text')
      const images = item.querySelector('.approach-steps__images')

      if (i <= this.activeIndex) {
        gsap.to(item, { height: 'auto', duration: this.duration, ease: this.ease, onComplete: this.renderThrottled })
        gsap.to(text, { opacity: 1, clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', duration: this.duration, ease: this.ease })
        gsap.to(images, { opacity: 1, duration: this.duration, ease: this.ease })
      } else {
        gsap.to(item, { height: '20rem', duration: this.duration, ease: this.ease, onComplete: this.renderThrottled })
        gsap.to(text, { opacity: 0, clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)', duration: this.duration, ease: this.ease })
        gsap.to(images, { opacity: 0, duration: this.duration, ease: this.ease })
      }
    })

    this.lastActiveIndex = this.activeIndex
  }

  onScroll() {
    if (this.lastScrollY > window.scrollY) {
      this.direction = 'up'
    }
    if (this.lastScrollY < window.scrollY) {
      this.direction = 'down'
    }

    this.lastScrollY = window.scrollY > 0 ? window.scrollY : 0

    this.items.forEach((item, i) => {
      const itemRect = item.getBoundingClientRect()

      if (itemRect.top < 0) {
        if (i + 1 < this.items.length) {
          this.activeIndex = i + 1
        }
      }

      if (this.activeIndex === this.items.length - 1) {
        return
      }

      if (itemRect.top >= 0 && i === 0) {
        this.activeIndex = 0
      }
    })

    this.render()
  }

  init() {
    this.initState()

    this.items = Array.from(
      document.querySelectorAll('.approach-steps__item')
    )
    const sliderItems = Array.from(
      document.querySelectorAll('.approach-steps__slider')
    )

    // if (this.items.length && !this.isMobile) {
    //   window.addEventListener('scroll', this.onScroll)
    // }

    this.swipers = sliderItems.map(slider => (
      new Swiper(slider, {
        speed: 1000,
        slidesPerView: 'auto'
      })
    ))
  }

  destroy() {
    window.removeEventListener('scroll', this.onScroll)
    this.swipers.forEach(swiper => swiper.destroy())
  }
}

export default new Component()
