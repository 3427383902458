class Component {
  initState() {
    this.currentLanguage = document.querySelector('.lang-picker__current')
    this.pickerTogglers = document.querySelectorAll('.lang-picker__link')

    this.pickerTogglers.forEach((item) => {
      item.addEventListener('click', () => {
        this.pickerTogglers.forEach((link) => {
          link.classList.remove('state-active')
          link.classList.remove('lang-picker__link--active')
        })

        item.classList.add('state-active')
        item.classList.add('lang-picker__link--active')
        this.currentLanguage.textContent = item.textContent
      })
    })
  }

  init() {
    this.initState()
  }
}

export default new Component()
