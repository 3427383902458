import Swiper, { Navigation } from 'swiper'

class Component {
  constructor() {
    this.onOpenClick = this.onOpenClick.bind(this)
    this.onCloseClick = this.onCloseClick.bind(this)
  }

  initState() {
    this.modalGallery = document.querySelector('.modal-gallery')
    this.slider = document.querySelector('.modal-gallery__slider')
    this.openBtn = document.querySelector('.hero-project__link')
    this.closeBtn = document.querySelector('.modal-gallery__close-btn')
    this.nav = document.querySelector('.modal-gallery__nav')

    this.duration = this.modalGallery ? parseFloat(getComputedStyle(this.modalGallery).transitionDuration) * 1000 : 0

    this.swiper = null
    this.isModal = false
    this.isMobile = window.matchMedia('(max-width: 767px)').matches
  }

  render() {
    if (this.isModal) {
      this.modalGallery.classList.add('modal-gallery--active')
    } else {
      this.modalGallery.classList.remove('modal-gallery--active')
    }
  }

  onOpenClick() {
    this.isModal = true
    this.render()
    this.swiper.update()
  }

  onCloseClick() {
    this.isModal = false
    this.render()

    setTimeout(() => {
      this.swiper.slideTo(0)
      this.nav.classList.remove('modal-gallery__nav--hidden')
    }, this.duration)
  }

  preventWheel(e) {
    e.preventDefault()
  }

  init() {
    this.initState()

    if (this.modalGallery) {
      Swiper.use([Navigation])

      this.swiper = new Swiper(this.slider, {
        speed: 1000,
        slidesPerView: 'auto',
        navigation: {
          prevEl: '.modal-gallery__nav-btn--prev',
          nextEl: '.modal-gallery__nav-btn--next'
        },
        breakpoints: {
          768: {
            speed: 1500
          }
        }
      })

      this.swiper.on('slideChange', ({ activeIndex }) => {
        if (activeIndex > 0 && this.isMobile) {
          this.nav.classList.add('modal-gallery__nav--hidden')
        }
      })

      this.openBtn && this.openBtn.addEventListener('click', this.onOpenClick)
      this.closeBtn && this.closeBtn.addEventListener('click', this.onCloseClick)
      this.modalGallery.addEventListener('wheel', this.preventWheel)
    }
  }

  destroy() {
    if (this.swiper) {
      this.swiper.destroy()
    }

    this.openBtn && this.openBtn.removeEventListener('click', this.onOpenClick)
    this.closeBtn && this.closeBtn.removeEventListener('click', this.onCloseClick)
    this.modalGallery && this.modalGallery.removeEventListener('wheel', this.preventWheel)
  }
}

export default new Component()
