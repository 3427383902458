class Component {
  init() {
    this.filter  = document.getElementById('filter')
    if (this.filter) {
      this.filter.addEventListener('mouseover', () => {
        this.buttons = document.querySelectorAll('.js-filter-hover')
        this.buttons.forEach(button => {
          button.addEventListener('mouseover', () => {
            this.buttons.forEach(otherButton => {
              if (otherButton !== button) {
                if (otherButton.classList.contains('active')) {
                  otherButton.classList.remove('active')
                  otherButton.classList.add('no-active')
                }
              }
            })
          })
          button.addEventListener('mouseout', () => {
            this.buttonNoActive = document.querySelectorAll('.no-active')
            this.buttonNoActive.forEach(buttonOff => {
              buttonOff.classList.add('active')
            })
          })
        })
      })
    }
  }
}

export default new Component()
