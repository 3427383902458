class Component {
  constructor() {
    this.onScroll = this.onScroll.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this)
    this.isActive = true
  }

  initState() {
    this.pageHeader = document.querySelector('.page-header')
    this.navButtons = document.querySelectorAll('.header__btn')
    this.navMenu = document.querySelector('.nav-menu')
    this.heroMain = document.querySelector('.hero-main')
    this.pageHeroLight = document.querySelector('.page-hero--light')
    this.isMobile = window.matchMedia('(max-width: 767px)').matches

    this.lastScrollY = 0
    this.direction = null
    this.isMenu = false
    this.isGradient = false
    this.isActive = true
    this.isLight = !!this.pageHeroLight

    if (this.heroMain) {
      this.isActive = false
    }

    this.render()
  }

  pageout() {
    this.isMenu = false
    this.render()
  }

  pagein() {
    if (this.heroMain) {
      this.heroMain.style.setProperty('--delay', '1.5s')
    }
  }

  animationend() {
    if (!this.heroMain) {
      this.isActive = true
      this.render()
    }
  }

  render() {
    if (!this.pageHeader) {
      return
    }

    if (this.isActive) {
      this.pageHeader.classList.add('page-header--active')
    } else {
      this.pageHeader.classList.remove('page-header--active')
    }

    if (this.isGradient) {
      this.pageHeader.classList.add('page-header--gradient')
    } else {
      this.pageHeader.classList.remove('page-header--gradient')
    }

    if (this.isMenu) {
      this.navMenu.classList.add('nav-menu--active')
      document.body.style.overflow = 'hidden'
    } else {
      this.navMenu.classList.remove('nav-menu--active')
      document.body.style.overflow = 'auto'
    }

    if (this.isLight) {
      this.pageHeader.classList.add('page-header--light')
    } else {
      this.pageHeader.classList.remove('page-header--light')
    }
  }

  onScroll() {
    if (this.lastScrollY > window.scrollY) {
      this.direction = 'up'
    }
    if (this.lastScrollY < window.scrollY) {
      this.direction = 'down'
    }

    this.lastScrollY = window.scrollY > 0 ? window.scrollY : 0

    if (this.direction === 'up') {
      if (this.heroMain) {
        const heroMainRect = this.heroMain.getBoundingClientRect()

        if (window.scrollY <= heroMainRect.height) {
          this.isActive = false
          this.render()
          return
        }
      }
      this.isActive = true
    }

    if (this.direction === 'down') {
      this.isActive = false
    }

    this.isGradient = window.scrollY > 200

    if (this.pageHeroLight) {
      this.isLight = window.scrollY <= this.pageHeroLight.clientHeight - this.pageHeader.clientHeight
    }

    this.render()
  }

  toggleMenu() {
    this.isMenu = !this.isMenu
    this.render()
  }

  init() {
    this.initState()

    if (!this.pageHeader) {
      return
    }

    window.addEventListener('scroll', this.onScroll)
    this.onScroll()

    Array.from(this.navButtons).forEach(btn => {
      btn.addEventListener('click', this.toggleMenu)
    })
  }

  destroy() {
    this.isMenu = false
    this.render()

    window.removeEventListener('scroll', this.onScroll)

    Array.from(this.navButtons).forEach(btn => {
      btn.removeEventListener('click', this.toggleMenu)
    })
  }
}

export default new Component()
